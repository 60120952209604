angular
  .module('mundoReporting')
  .directive(
    'focusOn'
    ($timeout) ->
      restrict: 'A'
      link: ($scope, $element, $attr) ->
        $scope.$watch(
          $attr.focusOn
          (focus_value) ->
            $timeout(
              () -> if focus_value then $element.focus() else $element.blur()
            )
        )
  )
